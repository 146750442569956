var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      staticClass: "mb-4",
      domProps: { innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.message")) }
    }),
    _c("div", {
      staticClass: "mb-4",
      domProps: {
        innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.instructions"))
      }
    }),
    _c("div", {
      staticClass: "font-weight-bold",
      domProps: { innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.step_one")) }
    }),
    _c("div", {
      staticClass: "mb-4",
      domProps: {
        innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.step_one_desc"))
      }
    }),
    _c("div", {
      staticClass: "font-weight-bold",
      domProps: { innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.step_two")) }
    }),
    _c("div", {
      domProps: {
        innerHTML: _vm._s(
          _vm.$t("modals.addCustomDomain.step_two_desc_hostname")
        )
      }
    }),
    _c("div", {
      domProps: {
        innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.step_two_desc_type"))
      }
    }),
    _c("div", {
      staticClass: "mb-4",
      domProps: {
        innerHTML: _vm._s(
          _vm.$t("modals.addCustomDomain.step_two_desc_destination", {
            group_slug: _vm.slug
          })
        )
      }
    }),
    _c("div", {
      staticClass: "font-weight-bold",
      domProps: {
        innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.step_three"))
      }
    }),
    _c("div", {
      staticClass: "mb-4",
      domProps: {
        innerHTML: _vm._s(_vm.$t("modals.addCustomDomain.step_three_desc"))
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }