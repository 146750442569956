var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "green-button-transparent border-0",
          attrs: {
            disabled:
              !_vm.isSysAdmin &&
              _vm.group.settings.customDomain !== _vm.group.domain &&
              _vm.group.settings.customDomain !== ""
          },
          on: {
            click: function($event) {
              return _vm.$bvModal.show(
                "modal-add-custom-domain-group-" + _vm.group.group_id
              )
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("buttons.verifyDomain")) + " ")]
      ),
      _c("b-modal", {
        ref: "modal-add-custom-domain-group-" + _vm.group.group_id,
        attrs: {
          id: "modal-add-custom-domain-group-" + _vm.group.group_id,
          "footer-class": "flex-nowrap",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("modals.addCustomDomain.title")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("modal-custom-domain-instructions", {
                      attrs: { slug: _vm.group.slug }
                    }),
                    _c("label", {
                      staticClass: "mb-2",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("modals.addCustomDomain.input")
                        )
                      }
                    }),
                    _c("b-form-input", {
                      attrs: { autofocus: "", debounce: "500" },
                      model: {
                        value: _vm.group.settings.customDomain,
                        callback: function($$v) {
                          _vm.$set(_vm.group.settings, "customDomain", $$v)
                        },
                        expression: "group.settings.customDomain"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return _vm.resetCustomDomain()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled:
                        _vm.group.settings.customDomain === "" ||
                        _vm.group.settings.customDomain === _vm.group.domain,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.verifyCustomDomain }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modals.addCustomDomain.validate")) +
                        " "
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }