<template>
  <div>
    <b-button
        @click="$bvModal.show('modal-add-custom-domain-group-'+group.group_id)"
        class="green-button-transparent border-0"
        :disabled="!isSysAdmin
          && group.settings.customDomain !== group.domain
          && group.settings.customDomain !== ''"
    >
      {{ $t('buttons.verifyDomain') }}
    </b-button>
    <b-modal
        :id="'modal-add-custom-domain-group-' + group.group_id"
        :ref="'modal-add-custom-domain-group-' + group.group_id"
        footer-class="flex-nowrap"
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.addCustomDomain.title') }}</h3>
      </template>

      <template #default>
        <div class="mt-4 mx-5 mb-4">
          <modal-custom-domain-instructions :slug="group.slug"></modal-custom-domain-instructions>
          <label class="mb-2" v-html="$t('modals.addCustomDomain.input')"></label>
          <b-form-input
              v-model="group.settings.customDomain"
              autofocus
              debounce="500"
          >
          </b-form-input>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="resetCustomDomain()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            :disabled="(group.settings.customDomain === '' ||
            group.settings.customDomain === group.domain)"
            variant="primary"
            class="p-3"
            block
            @click="verifyCustomDomain"
        >
          {{ $t('modals.addCustomDomain.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ModalCustomDomainInstructions from '@/components/modals/ModalCustomDomainInstructions.vue';

export default {
  name: 'ModalAddCustomDomain',
  components: { ModalCustomDomainInstructions },
  props: ['group', 'initialDomain', 'isSysAdmin'],
  methods: {
    resetCustomDomain() {
      this.group.settings.customDomain = this.initialDomain;
      const modalRef = `modal-add-custom-domain-group-${this.group.group_id}`;
      this.$refs[modalRef].hide();
    },
    verifyCustomDomain() {
      this.$emit('addCustomDomain', this.group);
      const modalRef = `modal-add-custom-domain-group-${this.group.group_id}`;
      this.$refs[modalRef].hide();
    },
  },
};
</script>

<style scoped>

</style>
