<template>
  <div>
    <div
        class="mb-4"
        v-html="$t('modals.addCustomDomain.message')"
    >
    </div>
    <div
        class="mb-4"
        v-html="$t('modals.addCustomDomain.instructions')"
    >
    </div>
    <div
        class="font-weight-bold"
        v-html="$t('modals.addCustomDomain.step_one')"
    >
    </div>
    <div class="mb-4" v-html="$t('modals.addCustomDomain.step_one_desc')"></div>
    <div
        class="font-weight-bold"
        v-html="$t('modals.addCustomDomain.step_two')"
    >
    </div>
    <div v-html="$t('modals.addCustomDomain.step_two_desc_hostname')"></div>
    <div v-html="$t('modals.addCustomDomain.step_two_desc_type')"></div>
    <div
        class="mb-4"
        v-html="$t('modals.addCustomDomain.step_two_desc_destination', { group_slug: slug })">
    </div>
    <div
        class="font-weight-bold"
        v-html="$t('modals.addCustomDomain.step_three')"
    >
    </div>
    <div class="mb-4" v-html="$t('modals.addCustomDomain.step_three_desc')"></div>
  </div>
</template>

<script>
export default {
  name: 'ModalCustomDomainInstructions',
  props: ['slug'],
};
</script>

<style scoped>

</style>
